import React, { useEffect } from 'react'
import externalLinks from 'utils/externalLinks'

function Challenge() {
  useEffect(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const referralCode = searchParams.get('referralCode')
      const referralCodeQuery = referralCode === null ? '' : '?referralCode=' + referralCode
      window.location.replace(externalLinks.tastyworksChallenge + referralCodeQuery);
  })
  return null;
}

export default Challenge;
